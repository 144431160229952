import React from "react";
import "../styles/loader.scss";
import { ImageConstants } from "./constants/Imageconstants";

const Loader = () => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     localStorage.setItem("items", JSON.stringify(true));
  //   }, 8000);
  // }, []);
  return (
    <>
      <div className="loader-main">
        <div className="movingdiv">
          <div className="leftSide">
            <div className="main">
              <div className="one">
                <img src={ImageConstants.animation1} alt="animation" />
              </div>
              <div className="two">
                <img src={ImageConstants.animation2} alt="animation" />
                <img src={ImageConstants.animation3} alt="animation" />
              </div>
            </div>
          </div>
          <div className="centerside">
            <div className="main">
              <div className="one"></div>
              <div className="tow"></div>
            </div>
          </div>
          <div className="rightSide">
            <div className="main">
              <div className="one">
                <img src={ImageConstants.animation5} alt="animation" />
                <img src={ImageConstants.animation6} alt="animation" />
              </div>
              <div className="two">
                <img src={ImageConstants.animation7} alt="animation" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
