import * as React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import { apiRoutes } from "../constants/apiRoutes";
import { useState, useEffect } from "react";
import { appendImage, createImage, createMarkup } from "../utils";
import "../styles/home.scss";
import Helmet from "../components/Helmet";
import routes from "../components/constants/routes";

import TuncHtml from "trunc-html";
import { ImageConstants } from "../components/constants/Imageconstants";
import { Placeholder } from "react-bootstrap";
import Loader from "../components/Loader";

export default function Home() {
  const [homedata, setHomedata] = useState();
  const [homenews, setHomenews] = useState();
  const [expandCenter, setExpandCenter] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [hideDiv, sethideDiv] = useState(true);
  // const [loaderfinish, setFinishLOader] = useState(false);
  // const [finishanimation, setFinishanimation] = useState(false);

  const truncate = (html) => {
    html = TuncHtml(html, 400).html;
    return html;
  };

  const homefechdata = async () => {
    try {
      const homeres = await axios.get(apiRoutes.homepage);
      setHomedata(homeres.data.data.attributes);
    } catch (error) {}
  };

  const homenewsdata = async () => {
    try {
      const homenewsres = await axios.get(apiRoutes.news(true, 5, undefined));
      setHomenews(homenewsres.data);
    } catch (error) {}
  };
  useEffect(() => {
    homefechdata();
    homenewsdata();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setExpandCenter(true);
    }, 1500);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeoutShowDiv = setTimeout(() => {
      setShowDiv(true);
    });

    return () => {
      clearTimeout(timeoutShowDiv);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      sethideDiv(false);
    }, 2100);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    appendImage("rich-editor");
  }, [homedata, homenews]);

  const seoData = {
    title: "About Festival Events Asbl | Explore Luxembourg's Culture",
    description:
      "Learn about Festival Events Asbl, your gateway to Luxembourg's arts, culture, and entertainment. Discover the richness of Luxembourg's history, technology, and gastronomy.",
    url: `${process.env.GATSBY_SITE_URL}`,
  };

  // useEffect(() => {
  //   const mydata = localStorage.getItem("items");
  //   const items = typeof mydata === "string" ? JSON.parse(mydata) : false;

  //   setFinishLOader(items);
  // }, []);
  //Ttile animation
  // useEffect(() => {
  //   setTimeout(() => {
  //     localStorage.setItem("title", JSON.stringify(true));
  //   }, 8000);
  // }, []);
  // useEffect(() => {
  //   const mytitle = localStorage.getItem("title");
  //   const title = typeof mytitle === "string" ? JSON.parse(mytitle) : false;

  //   setFinishanimation(title);
  // }, []);
  return (
    <Layout>
      <Helmet
        title={seoData.title}
        description={seoData.description}
        url={seoData?.url}
      />
      {/* {loaderfinish ? null : <Loader />} */}
      <Loader />
      <div className="about">
        {homedata?.herosection ? (
          <section className="hero-section">
            <div>
              <div>
                <div className="center-column ">
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(${createImage(
                        homedata?.herosection?.background_image?.data
                          ?.attributes?.url
                      )})`,
                    }}
                  >
                    <div className="homeHerotitle">
                      <div className="about-title">
                        <h1>{homedata?.herosection?.title}</h1>
                        <p>{homedata?.herosection?.sub_title}</p>
                      </div>

                      <div className="trsuted-image">
                        {homedata?.herosection?.trusted_images?.data.map(
                          (item) => {
                            return (
                              <figure key={item.id}>
                                <img
                                  src={createImage(item.attributes.url)}
                                  alt={
                                    homedata?.herosection
                                      ?.trusted_images_alt_text
                                  }
                                  width={100}
                                  height={100}
                                />
                              </figure>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <Placeholder animation="glow" className="Homeanimation">
            <Placeholder />
          </Placeholder>
        )}

        {homedata?.home_about_section?.map((item) => {
          return (
            <section className={item.title_class} key={item.item}>
              <div className="container-fluid pl-0 pr-0">
                <div className="row experience-container">
                  <div className="col-md-6">
                    <div className="section-one rich-editor">
                      <p
                        dangerouslySetInnerHTML={createMarkup(item.description)}
                      ></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="section-one-media">
                      {item?.carousel_image ? (
                        <figure key={item.id}>
                          <img
                            src={createImage(
                              item?.carousel_image?.data[0]?.attributes?.url
                            )}
                            alt={item?.carousel_image_alt_text}
                            width={1137}
                            height={752}
                          />
                        </figure>
                      ) : (
                        <Placeholder animation="glow">
                          <Placeholder />
                        </Placeholder>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className="row join-journey">
              <div className="col-md-8 d-none d-md-block content">
                <p className="font-18">
                  Join us for an exciting journey that celebrates the richness
                  and diversity of Luxembourg's arts, culture, history,
                  technology, and gastronomy. Explore captivating films, embark
                  on unforgettable adventures, and indulge in unique experiences
                  that will leave you inspired and entertained.
                </p>
              </div>
              <div className="col-md-4">
                <div className="join-journey-media">
                  <img
                    src="../image3.webp"
                    alt="Join us for an exciting journey"
                  />
                </div>
              </div>
            </div> */}
              </div>
            </section>
          );
        })}
        {homedata?.home_luxembourg.map((item) => {
          return (
            <section className={item.coloring_class} key={item.id}>
              <h1 className="title">{item.title}</h1>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className="content rich-editor">
                      <p
                        dangerouslySetInnerHTML={createMarkup(item.description)}
                      ></p>
                      <div className="action">
                        <button className="cmp-button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="48"
                            viewBox="0 -960 960 960"
                            width="48"
                          >
                            <path d="m702-301-43-42 106-106H120v-60h646L660-615l42-42 178 178-178 178Z" />
                          </svg>
                          <span className="cmp-button__text">
                            <Link to={item.cta_url}>Visit Website</Link>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="film-festival-media">
                      <figure>
                        <img
                          src={createImage(item?.image?.data?.attributes?.url)}
                          alt={item?.home_image_alt_text}
                          width={100}
                          height={100}
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          );
        })}

        {homenews?.data.length ? (
          <section className="latest-news">
            <h1 className="title">Latest News</h1>
            <div className="container">
              <div className="row">
                <div className="latest-news-container col-md-12">
                  <Swiper
                    modules={[
                      Navigation,
                      Pagination,
                      Scrollbar,
                      A11y,
                      Autoplay,
                    ]}
                    spaceBetween={20}
                    // slidesPerView={2}
                    // centeredSlides={true}
                    loop={true}
                    navigation
                    pagination={{ clickable: true }}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                    }}
                    slidesPerView={1.2}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 1.5,
                      },
                      1000: {
                        slidesPerView: 2,
                      },
                      1300: {
                        slidesPerView: 2,
                      },
                    }}
                  >
                    {homenews?.data.map((item, index) => {
                      const route =
                        item?.attributes?.type.data.attributes.newstype ===
                        "discover luxembourg"
                          ? routes.DLNews
                          : routes.FFNews;
                      return (
                        <SwiperSlide>
                          <div className="latest-news-card" key={item.it}>
                            <Link
                              to={`${route}?id=${item.id}`}
                              aria-label={"Discover Luxembourg"}
                            >
                              <img
                                src={createImage(
                                  item?.attributes?.image?.data?.attributes?.url
                                )}
                                alt="slider"
                                width={100}
                                height={100}
                              />
                            </Link>
                            <div
                              className={`content ${
                                item?.attributes?.type.data.attributes
                                  .newstype === "discover luxembourg"
                                  ? "bg-blue"
                                  : "bg-purple"
                              }`}
                            >
                              <Link
                                to={`${route}?id=${item.id}`}
                                aria-label={"Discover Luxembourg"}
                              >
                                <h2>{item.attributes.title}</h2>
                              </Link>
                              <div
                                className="font-18 mt-20 rich-editor latest-news"
                                dangerouslySetInnerHTML={createMarkup(
                                  truncate(item.attributes.description)
                                )}
                              ></div>
                              <h3 className="readmore mb-8">
                                <Link
                                  to={`${route}?id=${item.id}`}
                                  aria-label={"Read more"}
                                >
                                  {"Read more >>"}
                                </Link>
                              </h3>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
          </section>
        ) : null}
      </div>
    </Layout>
  );
}
